export enum POST_TYPES {
  BLOGPOSTS,
  STUDY,
}

export enum POST_STATUS {
  PUBLIC,
  DELETED,
  PRIVATE,
}
